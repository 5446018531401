var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"Nombre","header-props":{ sortByText: 'Organizar por' },"no-results-text":"Sin resultados","footer-props":{
    'items-per-page-text': 'items por página',
    'items-per-page-all-text': 'Todos',
  },"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","append-outer-icon":"mdi-plus"},on:{"click:append-outer":_vm.click},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Nuevo / editar "+_vm._s(_vm.tipo))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.listaCursos,"chips":"","clearable":"","hide-details":"","item-value":"nombre","item-text":"nombre","label":"Curso"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Este periodo no existe ")])],1)]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.periodo)}})],1)]}},{key:"selected",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.periodo)}})],1)]}}]),model:{value:(_vm.editedItem.curso),callback:function ($$v) {_vm.$set(_vm.editedItem, "curso", $$v)},expression:"editedItem.curso"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"notas"},model:{value:(_vm.editedItem.notas),callback:function ($$v) {_vm.$set(_vm.editedItem, "notas", $$v)},expression:"editedItem.notas"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{style:({ 'text-align': 'center' })},[_vm._v("Deseas borrar "+_vm._s(_vm.tipo)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isGuardando,"text":""},on:{"click":function($event){return _vm.deleteItemConfirm(_vm.item)}}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-progress-circular',{staticClass:"contenedor-tools__progress",attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }