<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    sort-by="Nombre"
    class="elevation-1"
    :header-props="{ sortByText: 'Organizar por' }"
    no-results-text="Sin resultados"
    :footer-props="{
      'items-per-page-text': 'items por página',
      'items-per-page-all-text': 'Todos',
    }"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            append-outer-icon="mdi-plus"
            @click:append-outer="click"
          ></v-text-field>
        </v-card-title>
        <v-dialog v-model="dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline"> Nuevo / editar {{ tipo }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-autocomplete
                      v-model="editedItem.curso"
                      :items="listaCursos"
                      chips
                      clearable
                      hide-details
                      item-value="nombre"
                      item-text="nombre"
                      label="Curso"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Este periodo no existe
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.periodo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:selected="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.periodo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.notas"
                      label="notas"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" text @click="dialog = false">
                Cerrar
              </v-btn>
              <v-btn color="primary" text @click="save"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title v-bind:style="{ 'text-align': 'center' }"
              >Deseas borrar {{ tipo }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" text @click="dialogDelete = false"
                >Cerrar</v-btn
              >
              <v-btn
                color="primary"
                :disabled="isGuardando"
                text
                @click="deleteItemConfirm(item)"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-progress-circular
        class="contenedor-tools__progress"
        :width="3"
        :size="20"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-data-table>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "ListEdit",
  // Ejemplo: tipo (cead), url (endpoint con el /), global (varibale global)
  props: ["tipo", "headers", "items", "url", "global", "title"],

  data() {
    return {
      isGuardando: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      text: null,
      listaCursos: [],
      edit: false,
      curso: null,
      nombre: null,
      editedItem: { curso: null, notas: null },
      defaultItem: { curso: null, notas: null },
    };
  },
  computed: mapState(["notasTrabajos"]),

  mounted() {
    this.cursos();
  },
  methods: {
    cursos() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data.lista;
            for (var c = 0; c < data.length; c++) {
              this.listaCursos.push({
                nombre: data[c]["cursoBase"],
                periodo: data[c]["periodo"],
              });
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    save() {
      if (this.edit == true) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "editar");
            fd.append("curso", this.editedItem.curso);
            fd.append("notas", this.editedItem.notas);
            axios.post(host + this.url, fd).then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.$store.commit("reduce", {
                  name: this.global,
                  edited: this.editedIndex,
                });
                this.$store.commit("add", {
                  name: this.global,
                  data: this.editedItem,
                });
              } else {
                this.$alert("No se puedo editar " + this.tipo);
              }
            });
            this.dialog = false;
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "agregar");
            fd.append("curso", this.editedItem.curso);
            fd.append("notas", this.editedItem.notas);
            axios.post(host + this.url, fd).then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.edit = false;
                this.$store.commit("add", {
                  name: this.global,
                  data: this.editedItem,
                });
              } else {
                this.$alert("No se puedo agregar " + this.tipo);
              }
            });
            this.dialog = false;
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.nombre = item.curso;
    },

    deleteItemConfirm() {
      this.isGuardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "borrar");
          fd.append("curso", this.editedItem.curso);
          axios.post(host + this.url, fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.isGuardando = false;
              this.$store.commit("reduce", {
                name: this.global,
                edited: this.editedIndex,
              });
            } else {
              this.$alert("No se puede eliminar el " + this.tipo);
            }
          });
          this.dialogDelete = false;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.edit = true;
    },

    click() {
      this.edit = false;
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>